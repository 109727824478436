import axios from "axios";

import { AppConstants } from "../../shared";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

apiClient.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem(AppConstants.accessToken);
  const language = localStorage.getItem(AppConstants.language);

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers["X-localization"] = `${language}`;
  }

  return request;
});

export async function getAccessToken(body) {
  return apiClient
    .post(`/oauth/token`, body)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getAllCities() {
  return apiClient
    .get(`/api/cities`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}
export async function getAllPlans() {
  return apiClient
    .get(`/api/subscriptionplan`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getCarWashPointsByCityAPI(cityId, orderType = "b2c") {
  return apiClient
    .get(`/api/cities/${cityId}/car_wash_points/order_type/${orderType}`)
    .then((response) => {
      if (response) {
     //   uploadSubsImage();
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}
export async function uploadSubsImage() {
  try {
    const response = await apiClient.get('/api/subscriptionplan/sk');
    console.log('response',response)
    if (response && response.data) {
      return response.data;
    } else {
      console.error('Error:else here');
      
    }
  } catch (error) {
    console.error('Error:', error.message);
    
  }
}
// export async function uploadSubsImage(formData) {
//   try {
//     const response = await apiClient.post('/api/subscriptionplan/upload_image', formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data', // Set the content type for form data
//       },
//     });

//     if (response && response.data) {
//       return response.data;
//     } else {
//       throw new Error('Error uploading image: Invalid response');
//     }
//   } catch (error) {
//     console.error('Error:', error.message);
//     throw new Error('Error uploading image: ' + error.message);
//   }
// }

 
export async function getAllServices() {
  return apiClient
    .get(`/api/services`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getAllServicesByCarWashPoint(carWashPointId) {
  console.log("getAllServicesByCarWashPoint")
  return apiClient
    .get(`/api/services/car_wash_point/${carWashPointId}`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getCarTypes() {
  return apiClient
    .get(`/api/car_types`)
    .then((response) => {
      if (response) {
        console.log('response',response)
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getCarTypesByServiceAndCarWashPoint(
  service_id,
  car_wash_point_id
) {
  return apiClient
    .get(
      `/api/car_types/service_id/${service_id}/car_wash_point/${car_wash_point_id}`
    )
    .then((response) => {
      if (response) {
        const rearrangedData = rearrangeCarTypes(response.data);
        return rearrangedData;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}
 function rearrangeCarTypes(carTypes) {
  console.log(carTypes)
  // Define the desired order of IDs
  const desiredOrder = [4, 6, 3,5, 1];

  // Create a map to quickly access items by ID
  const carTypesMap = new Map();
  carTypes.forEach((carType) => carTypesMap.set(carType.id, carType));

  // Rearrange the carTypes array based on desiredOrder
  const rearrangedCarTypes = desiredOrder.map((id) => carTypesMap.get(id)).filter(Boolean);

  // Add remaining cars (not in desiredOrder) to the end of the list
  carTypes.forEach((carType) => {
    if (!desiredOrder.includes(carType.id)) {
      rearrangedCarTypes.push(carType);
    }
  });

  return rearrangedCarTypes;
}

export async function getPackagesByMainServiceByCarWashPointByCarType(
  serviceId,
  carWashPointId,
  carTypeId
) {
  return apiClient
    .get(
      `/api/packages/service/${serviceId}/car_wash_point/${carWashPointId}/car_type/${carTypeId}`
    )
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getExtraServicesBySeriveAndCarWashPointAndCarTypeId(
  serviceId,
  carWashPointId,
  carTypeId
) {
  return apiClient
    .get(
      `/api/extra_services/service/${serviceId}/car_wash_point/${carWashPointId}/car_type/${carTypeId}`
    )
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

//
export async function getAvailableDatesByCarWash(carWashPointId) {
  return apiClient
    .get(`/api/car_wash_points/${carWashPointId}/available_dates`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getAvailableSlotsByDateAndPackageAndCarWashPointAndOrderType(
  date,
  packageId,
  carWashPointId,
  orderType,
  carType
) {
  return apiClient
    .get(
      `/api/packages/get_time_slots/date/${date}/package/${packageId}/car_wash_point/${carWashPointId}/order_type/${orderType}/car_type/${carType}/get_time_slots`
    )
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getCustomerDetailsByPhoneNumberAndPlateNumber(
  phoneNumber,
  plateNumber
) {
  return apiClient
    .get(
      `/api/customers/fetch_customer/phone/${phoneNumber}/plate_number/${plateNumber}`
    )
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getCarWashPointLocationsAPI(carWashPointId) {
  return apiClient
    .get(`/api/car_wash_points/${carWashPointId}/locations`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function postOrder(body) {
  return apiClient
    .post(`/api/orders/create`, body)
    .then((response) => {
      console.log(response, "response");
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function registerABusiness(body) {
  return apiClient
    .post(`/api/customers/sign_up_b2b`, body)
    .then((response) => {
      console.log(response, "response");
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function loginBusiness(body) {
  return apiClient
    .post(`/api/customers/login`, body)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function cancelOrder(body) {
  return apiClient
    .post(`/api/orders/cancel_order`, body)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getBanner() {
  return apiClient
    .get(`/api/settings/booking_page_design_settings`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export async function getSettings() {
  return apiClient
    .get(`/api/settings/general_settings`)
    .then((response) => {
      if (response) {
        return response.data;
      }
      return null;
    })
    .catch((err) => {
      console.log("Error = ", err);

      throw new Error(err.response.data.error.message);
    });
}

export default apiClient;
