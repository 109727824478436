import { useEffect, useState, React } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';
import { Helmet } from "react-helmet";

import './App.css';
import { Business } from './components/business/business';
import { PrivateCustomer } from './components/private-customer/private-customer';
import { getAccessToken, getBanner, getSettings } from './services/axios';
import { AppConstants } from './shared';
import { useTranslation } from 'react-i18next';
import { InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import swedish from './language.png';
import english from './english.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function App() {
  const [banner, setBanner] = useState({
    "title": "",
    "description": "",
    "logo": "",
    "banner": ""
  });
  const [accessToken, setAccessToken] = useState('');
  const [setting, setSetting] = useState('');
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAccessToken({
          grant_type: AppConstants.grantType,
          client_id: AppConstants.clientId,
          client_secret: AppConstants.clientSecret
        });
        console.log(res);
        if (res && res.access_token) {
          localStorage.setItem(AppConstants.accessToken, res.access_token);
          setAccessToken(res.access_token);
  
          const bannerStats = await getBanner();
          const settingGot = await getSettings();
  
          if (bannerStats && bannerStats.settings) {
            setBanner(bannerStats.settings);
          }
          console.log('Banner loaded');
          if (settingGot && settingGot.settings) {
            setSetting(settingGot.settings);
            setLoading(false);
            console.log('Settings loaded');
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []); // Ensure to include all dependencies here if needed
  

  return (
    <div>
      {banner && banner.logo && <Helmet>
        <title>{banner.title}</title>
        <meta name="description" content={banner.title}></meta>
        <link rel="icon" type="image/png" href="./alvis.ico" sizes="16x16" />
      </Helmet>}
      <div className="App-header">
        <Grid container spacing={3}>
          <Grid item xs>
          </Grid>
          <Grid item xs={10}>
            {loading && <Skeleton
              circle
              height="200px"
              width="200px"
            />}

            {!loading && <img style={{ maxWidth: '100%' }} src={banner.logo} alt='Logo' />}
          </Grid>
          <Grid item sx={{
            margin: 'auto',
            flexGrow: 1,
          }}>
            <img style={{ width: 56, height: 63, marginBottom: -25 }} src={swedish} alt='Logo' />
            <Select
              value={i18n.language}
              onChange={changeLanguage}
            >
              <MenuItem key={1} value={'en'}>  en</MenuItem>
              <MenuItem key={2} value={'sv'}>sv</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <div style={{ padding: 20, textAlign: 'center' }}>
          {loading && <Skeleton
            count={5}
          />

          }
          {!loading && <h1>{banner.title}</h1>}
          {!loading && <div dangerouslySetInnerHTML={{ __html: banner.description }} />}
          {!loading && <img style={{ maxWidth: '100%' }} src={banner.banner} alt='Banner' />}
        </div>
        <Router>
          <Switch>
            <Route path="/business-customer">
              {accessToken && !loading && <Business settings={setting} />}
            </Route>
            <Route path="/:cityid/:carwashpointid/:carwashlocationid/:serviceid/:cartypeid/:packageid" >
              <PrivateCustomer  settings={setting} />
            </Route>
            <Route path="/">
              {accessToken && !loading && <PrivateCustomer settings={setting} />}
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
